<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data(){
    return{}
  },
  created(){
    document.title = '智脉CRM';
    console.log('app')
  }
};
</script>

<style>
</style>
